import React, { useEffect, useState } from 'react';
import DataTable from './DataTable';
import './App.css';

const App = () => {
  const [customers, setCustomers] = useState([]);
  const [data, setData] = useState({});
  const [connectionStatus, setConnectionStatus] = useState('');
  const [formData, setFormData] = useState({
    purchase_date: '',
    shipment_date: '',
    customer_name: '',
    product_name: '',
    quantity: '',
    delivery_address: '',
    box_count: '',
    cubic_measurement: '',
    total: ''
  });
  const [editingData, setEditingData] = useState(null);

  const headers = ["purchase_date", "shipment_date", "customer_name", "product_name", "quantity", "delivery_address", "box_count", "cubic_measurement", "total"];
  const headerTitles = ["採購日期", "出貨日期", "客戶", "品名", "數量", "送貨地址", "箱數", "才數", "總數"];

  useEffect(() => {
    fetch('https://easyday.com.tw/api/test_api.php?action=testConnection')
      .then(response => response.json())
      .then(data => setConnectionStatus(data.message))
      .catch(error => setConnectionStatus('Connection failed: ' + error.message));

    fetch('https://easyday.com.tw/api/test_api.php?action=getCustomers')
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(customers => {
        setCustomers(customers.map(cust => cust.customer_name));
        return customers.map(cust => cust.customer_name);
      })
      .then(customers => {
        customers.forEach(customer_name => {
          fetch(`https://easyday.com.tw/api/test_api.php?action=getShipmentData&customer_name=${customer_name}`)
            .then(response => {
              if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
              }
              return response.json();
            })
            .then(data => {
              setData(prevData => ({ ...prevData, [customer_name]: data }));
            })
            .catch(error => console.error(`Error fetching data for ${customer_name}:`, error));
        });
      })
      .catch(error => console.error('Error fetching customers:', error));
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (editingData) {
      setEditingData({ ...editingData, [name]: value });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const dataToSubmit = editingData || formData;
    const method = editingData ? 'PUT' : 'POST';
    const action = editingData ? 'updateShipmentData' : 'addShipmentData';
    console.log('Submitting data:', dataToSubmit); // 添加log以便debug

    fetch(`https://easyday.com.tw/api/test_api.php?action=${action}`, {
      method,
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(dataToSubmit)
    })
    .then(response => response.json())
    .then(data => {
      console.log('Response data:', data); // 打印伺服器返回的數據
      if (data.message === 'Data inserted successfully' || data.message === 'Data updated successfully') {
        alert(data.message);
        window.location.reload(); // reload to fetch updated data
      } else {
        alert('Error submitting data');
      }
    })
    .catch(error => console.error('Error submitting data:', error));
  };

  const handleEditClick = (rowData) => {
    setEditingData(rowData);
  };

  const handleDeleteClick = (id) => {
    if (window.confirm('確定要刪除這筆資料嗎？')) {
      fetch(`https://easyday.com.tw/api/test_api.php?action=deleteShipmentData`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ id })
      })
      .then(response => response.json())
      .then(data => {
        console.log('Response data:', data); // 打印伺服器返回的數據
        if (data.message === 'Data deleted successfully') {
          alert('Data deleted successfully');
          window.location.reload(); // reload to fetch updated data
        } else {
          alert('Error deleting data');
        }
      })
      .catch(error => console.error('Error deleting data:', error));
    }
  };

  const handlePrintClick = () => {
    window.print();
  };

  return (
    <div className="app-container">
      <div className="form-container">
        <h1>{editingData ? '編輯資料' : '輸入資料'}</h1>
        <form onSubmit={handleFormSubmit}>
          {headers.map((header, idx) => (
            <div key={idx} className="form-group">
              <label>{headerTitles[idx]}</label>
              <input
                type={header.includes('date') ? 'date' : 'text'}
                name={header}
                value={editingData ? editingData[header] : formData[header]}
                onChange={handleInputChange}
                required
              />
            </div>
          ))}
          <button type="submit">{editingData ? '保存' : '提交'}</button>
        </form>
      </div>
      <div className="data-container">
        {/* <h1>連線狀態: {connectionStatus}</h1> */}
        {customers.map(customer_name => (
          <div key={customer_name} id={customer_name}>
            <h1>{customer_name} 出貨資料表</h1>
            <DataTable 
              data={data[customer_name] || []} 
              headers={headers} 
              headerTitles={headerTitles} 
              onEditClick={handleEditClick} 
              onDeleteClick={handleDeleteClick} 
            />
          </div>
        ))}
        <button className="print-button" onClick={handlePrintClick}>列印全部資料</button>
      </div>
    </div>
  );
};

export default App;
