import React from 'react';

const DataTable = ({ data, headers, headerTitles, onEditClick, onDeleteClick }) => {
  return (
    <table>
      <thead>
        <tr>
          {headerTitles.map((title, index) => (
            <th key={index}>{title}</th>
          ))}
          <th>操作</th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={index}>
            {headers.map((header, idx) => (
              <td key={idx}>{row[header]}</td>
            ))}
            <td>
              <button onClick={() => onEditClick(row)}>編輯</button>
              <button onClick={() => onDeleteClick(row.id)}>刪除</button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default DataTable;
